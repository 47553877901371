import { firebase } from '@/config/firebase.config.js';
import api from './api';
import { fetchUser } from './userApi';
export const login = async (email, password) => {
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const responseTwo = await api.post('login', {
      email,
      password,
    });
    console.log('logged in', { response, responseTwo });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const register = (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const logout = () => firebase.auth().signOut();

export const updatePassword = (password) =>
  firebase.auth().currentUser.updatePassword(password);

export const reauthenticateWithPassword = (email, password) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  return firebase.auth().currentUser.reauthenticateWithCredential(credential);
};

export const sendEmailLoginLink = (email, settings = {}) => {
  localStorage.setItem('emailLoginUserEmail', email);
  return firebase.auth().sendSignInLinkToEmail(email, {
    url: `${window.location.origin}/auth/magic-link?${
      settings.redirect ? `redirect=${settings.redirect}` : ''
    }`,
    handleCodeInApp: true,
    ...settings,
  });
};

export const signInWithEmailLink = (email) => {
  return firebase.auth().signInWithEmailLink(email, window.location.href);
};

export const onAuthStateChanged = (cb) => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        const userSnap = await fetchUser(userId);
        let data = {};
        if (userSnap.exists) {
          data = userSnap.data();
        }
        let payload = {
          ...user.user,
          ...data,
        };
        resolve(payload);
        // ...
        cb?.(payload);
      } else {
        // User is signed out
        // ...
        resolve(null);
        cb?.(null);
      }
    });
  });
};
